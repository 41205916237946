import React from 'react';
import './styles.scss';
import { Box, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';

const SystemFirstComponent = () => {
    return (
        <Box className='systemFirstComponent'>
            <Card className="systemFirstComponentCard" elevation={3}>
                <CardContent>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={8} className="systemFirstComponentMainContent">
                            <Typography variant="h4" component="h1" gutterBottom className='systemFirstComponentContentTitle'>
                                Comprehensive Security Suite
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                Equip your digital assets with top-tier security features designed to mitigate online risks and enhance transaction safety. This platform facilitates secure exchanges and maintains high operational standards.
                            </Typography>

                            <Typography variant="h6" component="h2" gutterBottom className='systemFirstComponentContentTitle'>
                                Key Benefits
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                Tailor the security settings to match your specific requirements. Leverage advanced analytics to optimize your defensive strategies and enhance system resilience against digital threats.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} className="systemFirstComponentSidebar">
                            <Box className='systemFirstComponentSideContent'>
                                <IconButton color="primary">
                                    <SecurityIcon style={{color:'#2a9d8f' , fontSize:'30px'}}/>
                                </IconButton>
                                <Typography variant="subtitle1" className='systemFirstComponentContentTitle'>
                                    Integrated Control
                                </Typography>
                                <Typography variant="body2" className='systemFirstComponentContent'>
                                    Centralize critical controls within a unified dashboard to improve oversight and streamline security management.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <IconButton color="primary">
                                    <SecurityIcon style={{color:'#2a9d8f', fontSize:'30px'}}/>
                                </IconButton>
                                <Typography variant="subtitle1" className='systemFirstComponentContentTitle'>
                                    Easy Implementation
                                </Typography>
                                <Typography variant="body2" className='systemFirstComponentContent'>
                                    Quickly integrate with minimal disruption, providing an intuitive interface that enhances user adaptability and satisfaction.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <IconButton color="primary">
                                    <SecurityIcon style={{color:'#2a9d8f', fontSize:'30px'}}/>
                                </IconButton>
                                <Typography variant="subtitle1" className='systemFirstComponentContentTitle'>
                                    Scalability
                                </Typography>
                                <Typography variant="body2" className='systemFirstComponentContent'>
                                    Grow your security capabilities as your business expands, with flexible solutions that scale to meet increasing demands.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default SystemFirstComponent;
