import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import CountUp from "react-countup";
import image from "../../../assets/images/homepage1.webp";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

const HomePageSecondComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const countUpRef = useRef();
  const cookie = new Cookies();

  const handleScroll = () => {
    const element = countUpRef.current;
    if (!isVisible && isElementInViewport(element)) {
      setIsVisible(true);
    }
  };

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);
  return (
    <Container>
      <Grid container className="HomePageSecondComponentContainer">
        <Grid item md={6} xs={12} className="HomePageSecondComponentContentContainer">
          <Box className="HomePageSecondComponentBoxContainer">
            <Typography variant="h5" className="HomePageSecondComponentTitle">
              Fortify your future with digital security.
            </Typography>
            <Typography
              variant="span18"
              className="HomePageSecondComponentSubTitle"
            > 
                Utilizing cutting-edge data analytics, our innovative security solution actively 
                protects digital assets. It continuously learns and adapts, forming a responsive barrier 
                to counteract emerging threats. Each new insight enhances our protective measures, providing robust,
                smart protection that not only secures but also predicts, guaranteeing uninterrupted security in the 
                digital landscape.
            </Typography>
            <Box sx={{ marginTop: "24px" }}>
              <Typography
                variant="span18"
                className="HomePageSecondComponentBoxTitle"
              >
                <div ref={countUpRef} style={{ marginBottom: "8px" }}>
                  {isVisible && (
                    <>
                      <CheckRoundedIcon className="HomePageSecondComponentBoxCheck" />{" "}
                      +<CountUp start={0} end={250} duration={1} />K Monitoring
                    </>
                  )}
                </div>
              </Typography>
              <Typography
                variant="span18"
                className="HomePageSecondComponentBoxTitle"
              >
                <div ref={countUpRef} style={{ marginBottom: "8px" }}>
                  {isVisible && (
                    <>
                      <CheckRoundedIcon className="HomePageSecondComponentBoxCheck" />{" "}
                      +<CountUp start={0} end={25} duration={2} />K Surveillance
                    </>
                  )}
                </div>
              </Typography>
              <Typography
                variant="span18"
                className="HomePageSecondComponentBoxTitle"
              >
                <div ref={countUpRef} style={{ marginBottom: "8px" }}>
                  {isVisible && (
                    <>
                      <CheckRoundedIcon className="HomePageSecondComponentBoxCheck" />{" "}
                      +<CountUp end={92} duration={1} />% Optimality
                    </>
                  )}
                </div>
              </Typography>
            </Box>

            {/* {!cookie.get("token") && (
              <Link to="/signin">
                <Button className="HomePageSecondComponentButton">
                  <Typography variant="span16">Get Started</Typography>
                </Button>
              </Link>
            )} */}
          </Box>
        </Grid>
        <Grid item md={6} xs={12} className="HomePageSecondComponentTitleContainer">
          <img src={image} width="100%" height="100%" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePageSecondComponent;
