import React from 'react'
import './styles.scss'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Container, Grid } from '@mui/material';
import { aboutUsServices } from '../../../data';



const AboutUsThirdComponent = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Container>
            <Box className="tab-container">
                <Box className="tabs">
                    {aboutUsServices.map((service, index) => (
                        <button
                            key={index}
                            className={`tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {service.title}
                        </button>
                    ))}
                </Box>
                <Box className="tab-content">
                    {aboutUsServices.map((service, index) => (
                        <Box
                            key={index}
                            className={`tab-panel ${activeTab === index ? 'active' : 'hidden'}`}
                        >
                            <img
                                src={service.icon}
                                alt={service.title}
                                style={{
                                    width: `${service.width}px`,
                                    height: `${service.height}px`,
                                }}
                            />
                            <Typography variant='span20'>{service.desc}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Container>
    );
}

export default AboutUsThirdComponent