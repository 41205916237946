import React from 'react'
import './styles.scss'
import { Box, Container, Grid, Typography } from '@mui/material'
import { aboutUs } from '../../../data'
import { arabToRoman } from 'roman-numbers'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const AboutUsSecondComponent = () => {
    return (
        <Box className="AboutUsSecondComponentWrapper">
            <Container sx={{ padding: '32px 0px', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                <Box className="AboutUsSecondComponentTitlesContainer">
                    <Typography variant='h2' className='AboutUsSecondComponentTitle'>
                        Reasons to Partner With Us
                    </Typography>
                </Box>
                <Box className='AboutUsSecondComponentCardsContainer'>
                    {
                        aboutUs?.map((about, index) => {
                            return (
                                <Box class="AboutUsSecondComponentCardsContainerCard">
                                    <Grid container spacing={3}>
                                        <Grid item sm={5} xs={12}>
                                            <Box class="AboutUsSecondComponentCardsContainerBox">
                                                <Box class="AboutUsSecondComponentCardsContainerContent">
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <h2 style={{ marginRight: "8px" }}><b>{index + 1}</b></h2>
                                                        <h4> {about?.title}</h4>
                                                    </Box>
                                                    <p>{about?.subTitle}</p>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={7} xs={12}>
                                            <Box class="AboutUsSecondComponentCardsImageContainer">
                                                <img src={about?.image} width='100%' height='100%' />
                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Container>
        </Box>
    )
}

export default AboutUsSecondComponent