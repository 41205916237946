import React from 'react'
import PageIntroduction from '../../component/common/introduction'
import HomePageFirstComponent from '../../component/homepage/homePageFirstComponent'
import HomePageFourthComponent from '../../component/homepage/homePageFourthComponent'
import HomePageSecondComponent from '../../component/homepage/homePageSecondComponent'
import HomePageThirdComponent from '../../component/homepage/homePageThirdComponent'
import './styles.scss'

const HomePage = () => {
    return (
        <>
            <PageIntroduction
                title="Cultivate Authentic Engagement, Overcome Fraudulent Schemes!"
                description1="Discover our triumphs in thwarting unauthorized account access and"
                description2="combatting incentive abuse within the entertainment industry."
            />
            <HomePageSecondComponent />
            <HomePageFourthComponent icon2 />
            <HomePageThirdComponent />
            <HomePageFirstComponent />
        </>
    )
}

export default HomePage