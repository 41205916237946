import React from 'react'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import AboutUsSecondComponent from '../../component/aboutus/aboutUsSecondComponent'
import AboutUsThirdComponent from '../../component/aboutus/aboutUsThirdComponent'
import PageIntroduction from '../../component/common/introduction'


const AboutUs = () => {
    return (
        <>
            <PageIntroduction title="Who We Are" description1="Our deep expertise in cybersecurity equips us with" description2="a comprehensive understanding of the obstacles you encounter." />
            <AboutUsSecondComponent />
            <AboutUsThirdComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default AboutUs