import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Box, Container } from '@mui/material'
import Cookies from 'universal-cookie'
import { getTickets } from '../../api'
import moment from 'moment'

const Tickets = () => {

    const cookie = new Cookies()
    const id = cookie.get('id');
    const [ticket, setTicket] = useState([]);

    useEffect(
        () => {
            getTickets(id).then(async res => {
                setTicket(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])
    return (
        <Box className="ticketsWrapper">
            <Box className='ticketsOverlayWrapper'>
                <h1>Keep track of your tickets.</h1>
            </Box>
            <Container>
                <Box className="ticketsContainer">
                    <table className='ticketProgressTable'>
                        <thead>
                            <tr>
                                <th align='center' className='tableHeader'><b>Ticket Number</b></th>
                                <th align='center' className='tableHeader'><b>Date</b></th>
                                <th align='center' className='tableHeader'><b>Status</b></th>
                            </tr>
                        </thead>
                        <tbody className='tbody'>
                            {
                                ticket.map(item => (
                                    <>
                                        <tr key={item._id} class="tableRow">
                                            <td align='center'><p style={{ marginTop: 16 }}>#{item?.generatedNumber}</p></td>
                                            <td align='center'><p style={{ marginTop: 16 }}>{moment(item.createdAt).format('L')}</p></td>
                                            <td align='center'>
                                                {
                                                    item.status === 0 ?
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'orange' }}>
                                                                {/* <Badge bg="warning"> */}
                                                                Pending
                                                                {/* </Badge> */}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: "green" }}>
                                                                {/* <Badge bg="success"> */}
                                                                Success
                                                                {/* </Badge> */}
                                                            </div>
                                                        </>
                                                }
                                            </td>
                                        </tr>

                                    </>

                                ))
                            }
                        </tbody>
                    </table>
                </Box>
            </Container>
        </Box>
    )
}

export default Tickets