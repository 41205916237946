import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import ScenariosFirstComponent from '../../component/scenarios/ScenariosFirstComponent'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import bg from '../../assets/images/scenarios-introduction.svg'

const Scenarios = () => {
    return (
        <>
            <PageIntroduciton
                title="Situational Forecasts"
                description1="Situational forecasts involve constructing detailed narratives of possible events"
                description2="that help guide strategic planning and informed decision-making processes."
            />
            <ScenariosFirstComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default Scenarios