import React from 'react'
import './styles.scss'
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import bye from '../../../assets/images/icons/bye.png'
import security from '../../../assets/images/icons/security.png'

const HomePageFirstComponent = () => {

    const mobile = useMediaQuery('(max-width:800px)')

    return (
        <>
            <Box className='HomePageFirstComponentWrapper'>
                <Box className="HomePageFirstComponentTitlesContainer">
                    <Typography variant='h2' className='HomePageFirstComponentTitle' fontWeight='bold'>
                        Trust in your digital interactions
                    </Typography>
                    <br />
                    <Typography variant='span18' className='HomePageFirstComponentSubTitle'>
                        We deploy advanced real-time behavioral analysis to ensure a secure,<br />
                        authentic online environment that fosters user confidence and safe engagement.
                    </Typography>

                </Box>
            </Box>
            <Box className='HomePageFirstComponentDataContainer' >
                <Box className="HomePageFirstComponentDataBox">
                    <Box className="HomePageFirstComponentDataImage">
                        <img src={bye} width={50} height={50} />
                    </Box>
                    <Typography variant='h6' className="HomePageFirstComponentDataText">Strengthen Brand Trust</Typography>
                    <Typography variant='span16' className="HomePageFirstComponentDataDesc">
                        Building brand trust involves consistently delivering on promises and maintaining transparency with customers. This commitment to reliability and open communication cultivates long-lasting customer loyalty.
                    </Typography>
                </Box>
                <Box className="HomePageFirstComponentDataBox">
                    <Box className="HomePageFirstComponentDataImage">
                        <img src={security} width={50} height={50} />
                    </Box>
                    <Typography variant='h6' className="HomePageFirstComponentDataText">Enhance Brand Reliability</Typography>
                    <Typography variant='span16' className="HomePageFirstComponentDataDesc">
                        To enhance brand reliability, companies must consistently offer high-quality products and responsive customer service. Establishing a track record of dependability strengthens consumer trust and supports business growth.
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default HomePageFirstComponent