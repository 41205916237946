import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import platform from '../../../assets/images/icons/platform.png';
import capabilities from '../../../assets/images/icons/capabilities.png';
import behavior from '../../../assets/images/icons/behavior.png';
import eye from '../../../assets/images/icons/eye.png';
import './styles.scss';
import bg from '../../../assets/images/homePage-image-2.svg'
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

const HomePageThirdComponent = () => {
    const cookie = new Cookies()
    return (
        <>
            <Box className='HomePageThirdComponentWrapper'>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={platform} width={40} height={40} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Integrated Dashboard</Typography>
                    <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>A consolidated platform to optimize workflow and enhance cross-functional activities.</Typography>
                    <Link to={cookie.get('token') ? '/contact-us' : '/signin'} className='HomePageThirdComponentDataButton'>
                        <Button sx={{color:'white'}}>Learn more</Button>
                    </Link>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={capabilities} width={40} height={40} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Expanded Capabilities</Typography>
                    <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Broadening the scope of functionalities to boost efficiency and performance.</Typography>
                    <Link to={cookie.get('token') ? '/contact-us' : '/signin'} className='HomePageThirdComponentDataButton'>
                        <Button sx={{color:'white'}}>Learn more</Button>
                    </Link>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={behavior} width={40} height={40} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>User Engagement Focus</Typography>
                    <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Adopting a user-centric approach to increase engagement and foster collaboration.</Typography>
                    <Link to={cookie.get('token') ? '/contact-us' : '/signin'} className='HomePageThirdComponentDataButton'>
                        <Button sx={{color:'white'}}>Learn more</Button>
                    </Link>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={eye} width={40} height={40} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Seamless Integration</Typography>
                    <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Streamlined integration capabilities that ensure a smooth user experience across platforms.</Typography>
                    <Link to={cookie.get('token') ? '/contact-us' : '/signin'} className='HomePageThirdComponentDataButton'>
                        <Button sx={{color:'white'}}>Learn more</Button>
                    </Link>
                </Box>
            </Box>

        </>

    )
}

export default HomePageThirdComponent