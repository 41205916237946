import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import pageBackground from '../../../assets/images/page-background.jpeg'
import './styles.scss'

const PageIntroduction = ({ title, description1, description2 }) => {
    return (
        <Box className="AboutUsFirstComponentContainer">
            <Box className='AboutUsFirstComponentOverlay'>
                <Grid container>
                    <Grid item md={12} sx={{ display: 'flex', justifyContent: "center", flexDirection: "column"}}>
                        <Typography variant='h1' className='AboutUsFirstComponentOverlay__Title'>
                            {title}
                        </Typography>
                        <Typography variant='span22' className='AboutUsFirstComponentOverlay__SubTitle'>
                            {description1}<br />{description2}
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
        </Box >
    )
}

export default PageIntroduction