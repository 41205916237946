import bet from './assets/images/icons/bet.png'
import car from './assets/images/icons/car.png'
import check from './assets/images/icons/check.png'
import fintech from './assets/images/icons/fintech.png'
import icon1 from './assets/images/icons/icon1.png'
import icon10 from './assets/images/icons/icon10.png'
import icon2 from './assets/images/icons/icon2.png'
import icon3 from './assets/images/icons/icon3.png'
import icon4 from './assets/images/icons/icon4.png'
import icon5 from './assets/images/icons/icon5.png'
import icon6 from './assets/images/icons/icon6.png'
import icon7 from './assets/images/icons/icon7.png'
import icon8 from './assets/images/icons/icon8.png'
import icon9 from './assets/images/icons/icon9.png'
import internet from './assets/images/icons/internet.png'
import system1 from './assets/images/icons/system1.png'
import system2 from './assets/images/icons/system2.png'
import system3 from './assets/images/icons/system3.png'
import system4 from './assets/images/icons/system4.png'
import system5 from './assets/images/icons/system5.png'
import system6 from './assets/images/icons/system6.png'
import aboutUs1 from './assets/images/aboutUs1.webp'
import aboutUs2 from './assets/images/aboutUs2.webp'
import aboutUs3 from './assets/images/aboutUs3.webp'
import aboutUs4 from './assets/images/aboutUs4.webp'

export const scenarios = [
    {
        name: 'Blocking Unauthorized Account Access',
        sub: 'Balancing robust security with user-friendly access controls.',
        icon: icon1,
        icon2: check,
        width: '70',
        height: '70'
    },
    {
        name: 'Preventing Fraud During Registration',
        sub: 'Establishing safeguards to deter fraud at the registration stage.',
        icon: icon2,
        icon2: check,
        width: '70',
        height: '70'
    },
    {
        name: 'Strengthening Transaction Safeguards',
        sub: 'Deploying a comprehensive protection strategy across all payment channels.',
        icon: icon3,
        icon2: check,
        width: '70',
        height: '60'
    },
    {
        name: 'Combating Credit Card Fraud',
        sub: 'Providing banks with advanced tools for real-time fraud detection and behavioral analytics.',
        icon: icon4,
        width: '70',
        height: '60'
    },
    {
        name: 'Identifying Money Laundering Risks',
        sub: 'Quickly identifying and mitigating risks associated with money mule activities.',
        icon: icon5,
        width: '65',
        height: '60'
    },
    {
        name: 'Thwarting Push Payment Fraud',
        sub: 'Utilizing behavioral analysis to combat scams originating from social engineering.',
        icon: icon6,
        width: '70',
        height: '70'
    },
    {
        name: 'Defending Against Policy Abuse',
        sub: 'Using behavioral biometrics to detect and prevent policy violations effectively.',
        icon: icon7,
        width: '70',
        height: '70'
    },
    {
        name: 'Protecting Against Bot Attacks',
        sub: 'Enhancing security with advanced behavioral analysis to counter automated threats.',
        icon: icon8,
        width: '70',
        height: '70'
    },
    {
        name: 'Securing Against Malware Attacks',
        sub: 'Implementing defenses to protect digital services from malware infiltrations.',
        icon: icon9,
        width: '70',
        height: '70'
    },
    {
        name: 'Enhancing E-Commerce Security',
        sub: 'Monitoring digital customer interactions to identify and prevent fraudulent activities.',
        icon: icon10,
        width: '70',
        height: '70'
    },
]


export const aboutUs = [
    {
        title: "Trusted Partners",
        subTitle: "Join our journey to establish a safer digital business environment by collaborating with a team dedicated to enhancing security and trust.",
        image: aboutUs1
    },
    {
        title: "Result-Oriented",
        subTitle: "We celebrate your successes by providing tailored security solutions that protect and enhance your operations, focusing on measurable and impactful results.",
        image: aboutUs2
    },
    {
        title: "Operational Efficiency",
        subTitle: "Boost your productivity and streamline your business processes with our cutting-edge solutions designed to optimize your operational efficiency.",
        image: aboutUs3
    },
    {
        title: "Brand Protection",
        subTitle: "Protect your brand from cyber threats with comprehensive strategies that shield your digital and physical assets, ensuring your reputation remains intact.",
        image: aboutUs4
    }
];

export const aboutUsServices = [
    {
        title: 'Next-Gen FinTech Security',
        icon: fintech,
        width: 250,
        height: 250,
        desc: 'Pioneering the future of financial technology, we deliver robust security solutions that safeguard digital financial activities. Our technology enables FinTech innovators to thrive by ensuring their platforms are secure and trustworthy.'
    },
    {
        title: 'Advanced Digital Financial Protection',
        icon: internet,
        width: 250,
        height: 250,
        desc: "Our cybersecurity framework provides essential protection for financial institutions during their digital transition. By securing sensitive information and financial operations, we help bridge the divide between high-security demands and optimal user experiences."
    },
    {
        title: 'Enhanced Online Gaming Security',
        icon: bet,
        width: 250,
        height: 250,
        desc: 'Dedicated to securing the online gaming sector, we protect platforms against cyber threats and fraud. Our solutions preserve the integrity and fairness of digital gaming environments, ensuring safety for user data and financial transactions.'
    },
    {
        title: 'Secure Automotive Technologies',
        icon: car,
        width: 250,
        height: 250,
        desc: 'As smart vehicles and connected automotive services proliferate, our cybersecurity measures provide critical protection. We safeguard these technologies against cyber threats, helping to maintain both consumer confidence and industry reputation.'
    },
];

export const system = [
    {
        title: 'User Experience',
        icon: system1,
        content: 'Enhancing user engagement with secure, multi-platform financial solutions designed for ease of use and optimal protection.',
    },
    {
        title: 'Security Indicators',
        icon: system2,
        content: 'Leveraging technology and behavioral data to craft detailed security profiles that cater to individual needs.',
    },
    {
        title: 'Cybersecurity Measures',
        icon: system3,
        content: 'Employing rigorous tech-based evaluations to fortify cybersecurity across all user devices against evolving threats.',
    },
    {
        title: 'User Behavior Monitoring',
        icon: system4,
        content: 'Advancing security by analyzing the nuanced interactions between users and their devices to detect irregularities.',
    },
    {
        title: 'Predictive Analytics',
        icon: system5,
        content: 'Utilizing advanced analytics to identify behavioral patterns and potential security threats before they arise.',
    },
    {
        title: 'Dynamic Risk Assessment',
        icon: system6,
        content: 'Continuously assessing and adjusting security protocols based on real-time risk analysis to enhance overall safety.',
    },
];




export const systemAccordion = [
    {
        id: 'panel1',
        title: 'Empower Customer Safety',
        content: 'Shift from passive awareness to active protection of your customers. Leverage our behavior-driven algorithms to verify identities, fortifying your digital platform and its users.',
    },
    {
        id: 'panel2',
        title: 'Unified Security Measures',
        content: 'Implement diverse anti-fraud strategies across various user interactions, integrating a range of security features into a cohesive solution. Customize as needed for scalable, hassle-free integration.',
    },
    {
        id: 'panel3',
        title: 'Effortless Integration',
        content: 'Our platform seamlessly integrates with existing third-party or custom solutions, enhancing and complementing your current security setup.',
    },
    {
        id: 'panel4',
        title: 'Comprehensive Fraud Prevention',
        content: 'Our holistic approach to fraud prevention covers all banking channels, centralizing fraud management to boost efficiency and control.',
    },
    {
        id: 'panel5',
        title: 'Efficient Fraud Management',
        content: 'Tailor your anti-fraud response using our flexible platform, designed for streamlined management and swift deployment, ensuring optimal returns.',
    },
    {
        id: 'panel6',
        title: 'Frictionless Security Solutions',
        content: 'Our biometric analysis minimizes user friction while enhancing security, providing a seamless authentication experience while upholding rigorous standards.',
    },
];


