import React from 'react'
import './styles.scss'
import { Box, Button, Container, Typography } from '@mui/material'
import { scenarios } from '../../../data'
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined';
import { Link } from 'react-router-dom';

const HomePageFourthComponent = ({ icon2 }) => {
    return (
        <Box className="HomePageFourthComponentWrapper">
            <Box className="HomePageFourthComponentTitlesContainer">
                <Typography variant='h2' className='HomePageFourthComponentTitle'>
                    Navigating Your Daily Digital Challenges
                </Typography>
                <Typography variant='span22' className='HomePageFourthComponentSubTitle'>
                    Explore how our solutions enhance your digital presence.
                </Typography>
            </Box>
            <Box className="HomePageFourthComponentCardsContainer">
                {
                    scenarios?.slice(0, 3)?.map(scenario => {
                        return (
                            <Box className="HomePageFourthComponentCard">
                                <Box className='HomePageFourthComponentCardIconContainer'>
                                    {
                                        !icon2 &&
                                        <img src={scenario?.icon} width={scenario?.width} height={scenario?.height} alt={scenario?.name} />
                                    }
                                    <Typography variant='span22' className='HomePageFourthComponentCardTitle'>
                                        {scenario?.name}
                                    </Typography>
                                </Box>
                                <Box className="HomePageFourthComponentCardTitlesContainer">
                                    <Typography variant='span16' className='HomePageFourthComponentCardSub'>
                                        {scenario?.sub}
                                    </Typography>
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default HomePageFourthComponent