import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { system } from '../../../data';
import './styles.scss';

const SystemSecondComponent = () => {

    return (
        <>
            <Box sx={{ margin: '0px 16px', display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center", gap: '16px' }}>
                {
                    system?.map((section, index) => {
                        return (
                            <Box class="card">
                            <Box class="card-content">
                              <img src={section?.icon} width={45} height={50} />
                              <p class="card-title">{section?.title}</p>
                              <p class="card-para">{section?.content}</p>
                            </Box>
                          </Box>
                          
                        )
                    })
                }

            </Box>
        </>
    )
}

export default SystemSecondComponent